import React from "react"
import styled from "styled-components"
import { B2DarkBlue, colors } from "../../styles/helpers"
import SpinnerAnimation from "../Animations/SpinnerAnimation"

const LoadingModal = ({ activeCat }) => {
  return (
    <LoadingModalStyled>
      <div className="innerLoading">
        <div className="innerLoading__spinner">
          <SpinnerAnimation />
        </div>
        <p>
          Loading more <span>{activeCat === "all" ? "" : activeCat}</span> posts
        </p>
      </div>
    </LoadingModalStyled>
  )
}

const LoadingModalStyled = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(47, 69, 96, 0.75);
  z-index: 999999;

  .innerLoading {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    align-self: center;
    background-color: ${colors.white};
    width: 40rem;
    margin: 0 auto;
    padding: 5rem 2rem;
    text-align: center;

    p {
      ${B2DarkBlue};
      margin: 0;

      span {
        color: ${colors.colorAccent};
        font-weight: bold;
        text-transform: uppercase;
      }
    }

    &__spinner {
      display: flex;
      flex-wrap: wrap;
      justify-content: center;
      align-self: center;
      width: 100%;
      height: 3.5rem;
      margin: 0 auto;
    }
  }
`

export default LoadingModal
